.brand-ford {
    .upcoming-livestream-wrapper {
        width: 90%;
        margin: 0 auto;
        padding-top: 60px;
        @include tablet {
            width: 80%;
            margin: 0 10%;
        }

        @include desktop {
            width: 80%;
            margin: 0 10%;
        }

        .upcoming-livestream-header-container {
            border-bottom: 1px solid $border-grey;

            .upcoming-schedule-subtitle {
                color: $fds-color-dk-gray;
                font-family: $f1-font-regular;
                font-size: 16px;
                line-height: 26px;
                display: flex;
                justify-content: center;
            }

            .upcoming-schedule-title {
                font-family: $f1-font-light;
                font-size: 34px;
                line-height: 44px;
                text-align: center;
                color: $fds-color-dk-gray;
                padding-top: 12px;
            }

            .upcoming-schedule-description {
                text-align: center;
                display: flex;
                justify-content: center;
                margin: auto;
                color: $fds-color-dk-gray;
                font-family: $f1-font-regular;
                font-size: 16px;
                line-height: 26px;
                padding-bottom: 16px;
                @include desktop {
                    width: 607px;
                    margin-top: 21px;
                }
            }
            .upcoming-schedule-description-tooltip {
                text-align: center;
                display: flex;
                justify-content: center;
                margin: auto;
                color: $fds-color-dk-gray;
                font-family: $f1-font-regular;
                font-size: 16px;
                line-height: 26px;
                padding-bottom: 16px;
                @include desktop {
                    margin-top: 21px;
                }

                .upcoming-schedule-description-wrapper {
                    .info-icon-tooltip {
                        .tooltip__button {
                            .tooltip__infoIcon {
                                &.dark {
                                    height: auto;
                                    width: auto;
                                    vertical-align: inherit;
                                    top: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
